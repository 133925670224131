// @file Function to define a container size store
import { observeVisualViewportSize } from '@@/bits/window'
import { BreakPoints } from '@@/pinia/window_size'
import { useResizeObserver, useWindowSize } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, readonly, ref, watch } from 'vue'

/**
 * Defines a container size Pinia store.
 * The created store behaves like `window_size` store until `observeContainerSize` is called, by then it
 * will update its state and breakpoints based on the container size.
 * @param name The name of the store.
 * @returns A Pinia store.
 */
export const defineContainerSizeStore = (name: string): typeof store => {
  const store = defineStore(name, () => {
    // The default container is the window.
    const { width: windowWidth, height: windowHeight } = useWindowSize()
    const containerWidth = ref(windowWidth)
    const containerHeight = ref(windowHeight)

    const resizeContainer = ({ width, height }: { width: number; height: number }): void => {
      containerWidth.value = width
      containerHeight.value = height
    }

    let unobserveWindowSizeChange: (() => void) | null = null
    let unobserveVisualViewportSize: (() => void) | null = null

    /**
     * Starts observing the window size to keep `containerWidth` and `containerHeight`
     * and the breakpoints up to date.
     */
    function observeWindowSize({ immediate }: { immediate?: boolean } = {}): void {
      unobserveWindowSizeChange = watch(
        [windowWidth, windowHeight],
        ([width, height]) => {
          resizeContainer({ width, height })
        },
        { immediate },
      )

      unobserveVisualViewportSize = observeVisualViewportSize(
        // On some mobile browsers, e.g. Google Chrome on iOS, when the window resize event is fired on device orientation change,
        // the new width / height is not available in the resize event yet
        // Reference: - https://stackoverflow.com/questions/65648544/clientwidth-and-innerwidth-not-updating-on-ios-chrome-after-orientationchange
        //            - https://stackoverflow.com/questions/12452349/mobile-viewport-height-after-orientation-change
        // We should also check for visual viewport change, this would ensure the window has been resized
        () =>
          resizeContainer({
            height: window.innerHeight,
            width: window.innerWidth,
          }),
      )
    }

    /**
     * Stops observing the window size.
     */
    function unobserveWindowSize(): void {
      unobserveWindowSizeChange?.()
      unobserveVisualViewportSize?.()
    }

    let stopObservingContainerSize: (() => void) | null = null

    /**
     * Starts observing the container element size.
     * The breakpoints will be based on the new size.
     */
    function observeContainerSize(containerElement: HTMLElement): void {
      if (stopObservingContainerSize != null) {
        throw new Error('[containerSizeStore] Already observing a container. Call `unobserveContainerSize` first.')
      }

      unobserveWindowSize()

      // Immediately resize the container. Otherwise, the breakpoints won't be updated
      // until the next resize event.
      resizeContainer(containerElement.getBoundingClientRect())
      const { stop } = useResizeObserver(containerElement, ([entry]) => {
        resizeContainer(entry.contentRect)
      })
      stopObservingContainerSize = stop
    }

    /**
     * Stops observing the container element size.
     * The breakpoints will revert back to be based on the window size.
     */
    function unobserveContainerSize(): void {
      stopObservingContainerSize?.()
      observeWindowSize({ immediate: true })
    }

    /** By default, observe the window size so that this store acts like window_size store. */
    observeWindowSize()

    // BREAKPOINTS
    // If you are looking for something like `isContainerSmallerThanOrEqualToTabletLandscape`,
    // negate `isContainerBiggerThanTabletLandscape` instead.
    const containerBiggerThanBreakpoints = {
      isContainerBiggerThanPhone: computed(() => containerWidth.value > BreakPoints.Phone),
      isContainerBiggerThanTabletPortrait: computed(() => containerWidth.value > BreakPoints.TabletPortrait),
      isContainerBiggerThanTabletLandscape: computed(() => containerWidth.value > BreakPoints.TabletLandscape),
      isContainerBiggerThanDesktop: computed(() => containerWidth.value > BreakPoints.Desktop),
      isContainerBiggerThanDesktopBig: computed(() => containerWidth.value > BreakPoints.DesktopBig),
      isContainerBiggerThanDesktopBig2XL: computed(() => containerWidth.value > BreakPoints.Desktop2XL),
    }
    const containerSmallerThanBreakpoints = {
      isContainerSmallerThanPhone: computed(() => containerWidth.value < BreakPoints.Phone),
      isContainerSmallerThanTabletPortrait: computed(() => containerWidth.value < BreakPoints.TabletPortrait),
      isContainerSmallerThanTabletLandscape: computed(() => containerWidth.value < BreakPoints.TabletLandscape),
      isContainerSmallerThanDesktop: computed(() => containerWidth.value < BreakPoints.Desktop),
      isContainerSmallerThanDesktopBig: computed(() => containerWidth.value < BreakPoints.DesktopBig),
      isContainerSmallerThanDesktop2XL: computed(() => containerWidth.value < BreakPoints.Desktop2XL),
      isContainerSmallerThanDesktop3XL: computed(() => containerWidth.value < BreakPoints.Desktop3XL),
    }

    // OLD BREAKPOINTS, to be deprecated eventually
    const isContainerPhone = computed(() => containerWidth.value < 768)
    const isContainerSmallPhone = computed(() => containerWidth.value <= 375)

    return {
      containerWidth: readonly(containerWidth),
      containerHeight: readonly(containerHeight),
      ...containerBiggerThanBreakpoints,
      ...containerSmallerThanBreakpoints,
      isContainerPhone,
      isContainerSmallPhone,
      observeContainerSize,
      unobserveContainerSize,
    }
  })
  return store
}
